import { Jodit } from "jodit-react";

const basicJodithConfig = {
  readonly: false,
  askBeforePasteHTML: true,
  // defaultActionOnPaste: "insert_only_text",
  placeholder: "Start typings...",
  defaultFontName: "",
  cleanHTML: {
    fillEmptyParagraph: true,
  },
  height: 280,
  style: {
    fontSize: "14px",
    fontFamily: "Sorts Mill Goudy",
  },
  buttons: [...Jodit.defaultOptions.buttons],
  removeButtons: ["eraser", "about", "print", "file", "video", "subscript", "superscript", "copyformat", "spellcheck"],
  controls: {
    fontsize: {
      list: {
        12: "12",
        14: "14",
        16: "16",
        18: "18",
        20: "20",
        22: "22",
        34: "34",
      },
    },

    font: {
      list: {
        "Sorts Mill Goudy": "Sorts Mill Goudy",
      },
    },
  },
  colors: {
    greyscale: ["#000000", "#444444", "#434343", "#666666", "#999999", "#B7B7B7", "#CCCCCC", "#D9D9D9", "#EFEFEF", "#F3F3F3", "#FFFFFF"],
    palette: ["#946E57", "#3F5263", "#963E24"],
  },
  uploader: { insertImageAsBase64URI: true },
};

export default basicJodithConfig;
