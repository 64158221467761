// hooks
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
// import { DevTool } from "@hookform/devtools";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

// config
import { InputNumber } from "primereact/inputnumber";
import contentType from "../../assets/data/content-type.json";

// api related
import { InputSwitch } from "primereact/inputswitch";
import Api from "../../api/Api";
import { Image } from "primereact/image";
import { MultiSelect } from "primereact/multiselect";

const HeaderSettingCreate = () => {
  const navigate = useNavigate();

  // hooks
  const { control, register, handleSubmit } = useForm();

  // query
  const { data: imageOptions } = useQuery({
    queryKey: ["header-image"],
    queryFn: () => getHeaderImage(),
  });
  const { data: contentOption } = useQuery({
    queryKey: ["content-options"],
    queryFn: () => getContent(),
  });

  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/header", data), {
    onSettled: (response) => {
      if (response.data.status === 200) {
        navigate("/dashboard/header");
      } else {
        toast.error(response.data.message, { duration: 5000 });
      }
    },
  });

  // functions
  const getHeaderImage = async () => {
    try {
      const res = await Api().get(`/header-image?limit=100`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };
  const getContent = async () => {
    try {
      const res = await Api().get(`/content?limit=50&active_status=true`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      let result = res.data.data;
      let temp_result = [];

      for (let i = 0; i < result.length; i++) {
        const currentContent = result[i];
        const type = contentType.find((c) => c.value === currentContent.type);
        const existingIndex = temp_result.findIndex((ts) => ts.label === type.label);

        if (existingIndex >= 0) {
          temp_result[existingIndex].items.push({ label: currentContent.title, value: currentContent._id });
        } else {
          temp_result.push({
            label: type.label,
            code: type.label,
            items: [{ label: currentContent.title, value: currentContent._id }],
          });
        }
      }

      return temp_result;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = (data) => {
    crateMutate(data);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create Header
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const ImageOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width="50" src={option?.images[0]?.url} />
        <div className="pl-4">{option.title}</div>
      </div>
    );
  };
  const ContentOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div className="pl-4">{option.label}</div>
      </div>
    );
  };

  const groupedItemTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.label}</div>
      </div>
    );
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="card col-12 mx-auto">
        <Toolbar className="mb-4" left={leftToolbar} />
        <div className="p-fluid formgrid grid ">
          <div className="field col-12 ">
            <label htmlFor="user">Name :</label>
            <InputText placeholder="Input page name" {...register("name")} id="name" type="text" />
          </div>
          <div className="field col-12 ">
            <label htmlFor="user">Route :</label>
            <InputText placeholder="Example /stories" {...register("route")} id="route" type="text" />
          </div>
          <div className="field col-12 ">
            <label htmlFor="order">Order :</label>
            <Controller defaultValue="" control={control} name={"order"} render={({ field }) => <InputNumber onBlur={field.onBlur} ref={field.ref} value={field.value} onValueChange={(e) => field.onChange(e)} showButtons mode="decimal" placeholder="0" />} />
          </div>
          <div className="field col-12">
            <label htmlFor="order">Images :</label>
            <Controller
              control={control}
              name="images"
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  itemTemplate={ImageOptionTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!imageOptions?.length}
                  value={field.value}
                  options={imageOptions}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select image"
                  display="chip"
                />
              )}
            />
          </div>
          <div className="field col-12">
            <label htmlFor="contents">Content :</label>
            <Controller
              control={control}
              name="contents"
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                  optionLabel="label"
                  optionValue="value"
                  itemTemplate={ContentOptionTemplate}
                  optionGroupTemplate={groupedItemTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!contentOption?.length}
                  value={field.value}
                  options={contentOption}
                  onChange={(e) => field.onChange(e)}
                  placeholder="Select image"
                  display="chip"
                />
              )}
            />
          </div>
          <div className="field col-12 ">
            <label htmlFor="email">Active Status</label>
            <Controller
              control={control}
              defaultValue={false}
              name="active_status"
              render={({ field }) => (
                <InputSwitch
                  className="block"
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  checked={field.value}
                />
              )}
            />
          </div>
        </div>

        <div className="flex justify-content-center mt-4">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard/header">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(HeaderSettingCreate, comparisonFn);
