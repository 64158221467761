import Users from "./pages/Users/Users";
import Role from "./pages/Role/Role";
import Page from "./pages/PageSetting/Page";
import CreatePage from "./pages/PageSetting/CreatePage";
import CreateRole from "./pages/Role/CreateRole";
import UpdateRole from "./pages/Role/UpdateRole";
import AccountSetting from "./pages/v2/AccountSetting";
import UpdateUser from "./pages/Users/UpdateUser";
import HomeBanner from "./pages/HomeBanner/HomeBanner";
import UpdateHomePage from "./pages/HomePageSetting/UpdateHomePage";
import HeaderSetting from "./pages/Header/HeaderSetting";
import HeaderSettingCreate from "./pages/Header/HeaderSettingCreate";
import HeaderSettingUpdate from "./pages/Header/HeaderSettingUpdate";

import StaticPageUpdate from "./pages/StaticPage/StaticPageUpdate";
import StaticPageCreate from "./pages/StaticPage/StaticPageCreate";
import StaticPage from "./pages/StaticPage/StaticPage";
import HeaderImagePage from "./pages/HeaderImagePage";
import Blog from "./pages/Blog/Blog";
import BlogCreate from "./pages/Blog/BlogCreate";
import BlogUpdate from "./pages/Blog/BlogUpdate";
import BlogImages from "./pages/Blog/BlogImages";
import Destination from "./pages/Destination/Destination";
import DestinationCreate from "./pages/Destination/DestinationCreate";
import DestinationUpdate from "./pages/Destination/DestinationUpdate";
import DestinationImages from "./pages/Destination/DestinationImages";
import Vessel from "./pages/Vessel/Vessel";
import VesselCreate from "./pages/Vessel/VesselCreate";
import VesselUpdate from "./pages/Vessel/VesselUpdate";
import VesselImages from "./pages/Vessel/VesselImages";
import TravelImages from "./pages/Travel/TravelImages";
import Travel from "./pages/Travel/Travel";
import TravelCreate from "./pages/Travel/TravelCreate";
import TravelUpdate from "./pages/Travel/TravelUpdate";
import LifeOnBoard from "./pages/LifeOnBoard/LifeOnBoard";
import LifeOnBoardCreate from "./pages/LifeOnBoard/LifeOnBoardCreate";
import LifeOnBoardUpdate from "./pages/LifeOnBoard/LifeOnBoardUpdate";
import LifeOnBoardImages from "./pages/LifeOnBoard/LifeOnBoardImages";
import DestinationPage from "./pages/Destination/DestinationPage";
import AboutImages from "./pages/About/AboutImages";
import About from "./pages/About/About";
import VesselPage from "./pages/Vessel/VesselPage";
import LifeOnBoardPage from "./pages/LifeOnBoard/LifeOnBoardPage";
import TrevelPage from "./pages/Travel/TrevelPage";
import BlogPage from "./pages/Blog/BlogPage";
import VesselDetails from "./pages/VesselDetails/VesselDetails";
import VesselDetailsCreate from "./pages/VesselDetails/VesselDetailsCreate";
import VesselDetailsUpdate from "./pages/VesselDetails/VesselDetailsUpdate";
import VesselDetailsImages from "./pages/VesselDetails/VesselDetailsImages";
import Enquire from "./pages/Enquire/Enquire";
import HomePageImages from "./pages/HomePageImages";

const pagesList = [
  // with parent

  {
    route: "/dashboard/header",
    component: HeaderSetting,
    childs: [
      {
        route: "/dashboard/header/create",
        component: HeaderSettingCreate,
        access: "create",
      },
      {
        route: "/dashboard/header/update/:id",
        component: HeaderSettingUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/blog",
    component: Blog,
    childs: [
      {
        route: "/dashboard/blog/create",
        component: BlogCreate,
        access: "create",
      },
      {
        route: "/dashboard/blog/update/:id",
        component: BlogUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/destination",
    component: Destination,
    childs: [
      {
        route: "/dashboard/destination/create",
        component: DestinationCreate,
        access: "create",
      },
      {
        route: "/dashboard/destination/update/:id",
        component: DestinationUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/vessel",
    component: Vessel,
    childs: [
      {
        route: "/dashboard/vessel/create",
        component: VesselCreate,
        access: "create",
      },
      {
        route: "/dashboard/vessel/update/:id",
        component: VesselUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/vessel-details",
    component: VesselDetails,
    childs: [
      {
        route: "/dashboard/vessel-details/create",
        component: VesselDetailsCreate,
        access: "create",
      },
      {
        route: "/dashboard/vessel-details/update/:id",
        component: VesselDetailsUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/travel",
    component: Travel,
    childs: [
      {
        route: "/dashboard/travel/create",
        component: TravelCreate,
        access: "create",
      },
      {
        route: "/dashboard/travel/update/:id",
        component: TravelUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/life-on-board",
    component: LifeOnBoard,
    childs: [
      {
        route: "/dashboard/life-on-board/create",
        component: LifeOnBoardCreate,
        access: "create",
      },
      {
        route: "/dashboard/life-on-board/update/:id",
        component: LifeOnBoardUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/static-page",
    component: StaticPage,
    childs: [
      {
        route: "/dashboard/static-page/create",
        component: StaticPageCreate,
        access: "create",
      },
      {
        route: "/dashboard/static-page/update/:id",
        component: StaticPageUpdate,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/role",
    component: Role,
    childs: [
      {
        route: "/dashboard/role/create",
        component: CreateRole,
        access: "create",
      },
      {
        route: "/dashboard/role/update/:id",
        component: UpdateRole,
        access: "update",
      },
    ],
    hasChild: true,
  },
  {
    route: "/dashboard/pages",
    component: Page,
    childs: [
      {
        route: "/dashboard/pages/create",
        component: CreatePage,
        access: "create",
      },
    ],
    hasChild: true,
  },

  {
    route: "/dashboard/users",
    component: Users,
    childs: [
      {
        route: "/dashboard/users/update/:id",
        component: UpdateUser,
        access: "update",
      },
    ],
    hasChild: true,
  },

  // without parent
  {
    route: "/dashboard/life-on-board-page",
    component: LifeOnBoardPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/blog-page",
    component: BlogPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/travel-page",
    component: TrevelPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/enquire",
    component: Enquire,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/vessel-page",
    component: VesselPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/vessel-details-images",
    component: VesselDetailsImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/about-page",
    component: About,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/about-images",
    component: AboutImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/blog-images",
    component: BlogImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/destination-images",
    component: DestinationImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/destination-page",
    component: DestinationPage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/vessel-images",
    component: VesselImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/travel-images",
    component: TravelImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/life-on-board-images",
    component: LifeOnBoardImages,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/header-image",
    component: HeaderImagePage,
    childs: [],
    hasChild: false,
  },

  {
    route: "/dashboard/home-page",
    component: UpdateHomePage,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/home-banner",
    component: HomeBanner,
    childs: [],
    hasChild: false,
  },
  {
    route: "/dashboard/home-page-images",
    component: HomePageImages,
    childs: [],
    hasChild: false,
  },

  // dashboard/details is hidden , i hide in login file
  {
    route: "/dashboard/account",
    component: AccountSetting,
    childs: [],
    hasChild: false,
  },
];

export default pagesList;
