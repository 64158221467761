// hooks
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { InputTextarea } from "primereact/inputtextarea";
import ReactImageUploading from "react-images-uploading";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { useState } from "react";
import MultiSelectImagesCRUD from "../../components/MultiSelectImagesCRUD";

const LifeOnBoardPage = () => {
  let type = 4;
  let name = "Life on Board";
  const [id, setId] = useState("");

  const [images, setImages] = useState([]);
  const [imagesMobile, setImagesMobile] = useState([]);

  const [images3, setImages3] = useState([]);
  const [images3Mobile, setImages3Mobile] = useState([]);

  const [images4, setImages4] = useState([]);
  const [images4Mobile, setImages4Mobile] = useState([]);

  const [images5, setImages5] = useState([]);
  const [images5Mobile, setImages5Mobile] = useState([]);

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  const { data: imagesOption, isLoading: loadingImageOption, refetch } = useQuery(["content-image", type], () => getImageOptions());
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/lifeonboard/edit", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }
        toast.success("Success updated!");
        getDetails();
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  // functions
  const onSubmit = (data) => {
    const formData = new FormData();
    let file_key = ["files_thumbnail", "files_mobile", "files", "files2", "files_mobile2", "files3", "files_mobile3", "files4", "files_mobile4", "files5", "files_mobile5"];
    // if data is array then its gonna looop

    let array_key = ["files4", "files_mobile4", "files5", "files_mobile5"];

    data.id = id;
    data.banner = JSON.stringify(data.banner || []);
    data.destinations = JSON.stringify(data.destinations || []);
    data.vessels = JSON.stringify(data.vessels || []);
    data.journals = JSON.stringify(data.journals || []);
    data.section1_content_images = JSON.stringify(data.section1_content_images || []);

    // image
    if (images.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < images.length; i++) {
        // this mean its a prev image
        if (images[i].data_url.includes("https")) {
          temp_prev_images.push(images[i].file);
        } else {
          temp_image.push(images[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section1_images = JSON.stringify(temp_prev_images);
      } else {
        data.section1_images = JSON.stringify([]);
        // delete data.section1_images;
      }
      if (temp_image.length) {
        data.files = temp_image;
      }
    }

    if (imagesMobile.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < imagesMobile.length; i++) {
        // this mean its a prev image
        if (imagesMobile[i].data_url.includes("https")) {
          temp_prev_images.push(imagesMobile[i].file);
        } else {
          temp_image.push(imagesMobile[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section1_images_mobile = JSON.stringify(temp_prev_images);
      } else {
        data.section1_images_mobile = JSON.stringify([]);
        // delete data.section1_images_mobile;
      }
      if (temp_image.length) {
        data.files_mobile = temp_image;
      }
    }

    if (images3.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < images3.length; i++) {
        // this mean its a prev image
        if (images3[i].data_url.includes("https")) {
          temp_prev_images.push(images3[i].file);
        } else {
          temp_image.push(images3[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section3_images = JSON.stringify(temp_prev_images);
      } else {
        data.section3_images = JSON.stringify([]);
        // delete data.section3_images;
      }
      if (temp_image.length) {
        data.files3 = temp_image;
      }
    }

    if (images3Mobile.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < images3Mobile.length; i++) {
        // this mean its a prev image
        if (images3Mobile[i].data_url.includes("https")) {
          temp_prev_images.push(images3Mobile[i].file);
        } else {
          temp_image.push(images3Mobile[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section3_images_mobile = JSON.stringify(temp_prev_images);
      } else {
        data.section3_images_mobile = JSON.stringify([]);
        // delete data.section3_images_mobile;
      }
      if (temp_image.length) {
        data.files_mobile3 = temp_image;
      }
    }

    if (images4.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < images4.length; i++) {
        // this mean its a prev image
        if (images4[i].data_url.includes("https")) {
          temp_prev_images.push(images4[i].file);
        } else {
          temp_image.push(images4[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section4_images = JSON.stringify(temp_prev_images);
      } else {
        data.section4_images = JSON.stringify([]);
        // delete data.section4_images;
      }
      if (temp_image.length) {
        data.files4 = temp_image;
      }
    }

    if (images4Mobile.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < images4Mobile.length; i++) {
        // this mean its a prev image
        if (images4Mobile[i].data_url.includes("https")) {
          temp_prev_images.push(images4Mobile[i].file);
        } else {
          temp_image.push(images4Mobile[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section4_images_mobile = JSON.stringify(temp_prev_images);
      } else {
        data.section4_images_mobile = JSON.stringify([]);
        // delete data.section4_images_mobile;
      }
      if (temp_image.length) {
        data.files_mobile4 = temp_image;
      }
    }

    if (images5.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < images5.length; i++) {
        // this mean its a prev image
        if (images5[i].data_url.includes("https")) {
          temp_prev_images.push(images5[i].file);
        } else {
          temp_image.push(images5[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section5_images = JSON.stringify(temp_prev_images);
      } else {
        data.section5_images = JSON.stringify([]);
        // delete data.section5_images;
      }
      if (temp_image.length) {
        data.files5 = temp_image;
      }
    }

    if (images5Mobile.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < images5Mobile.length; i++) {
        // this mean its a prev image
        if (images5Mobile[i].data_url.includes("https")) {
          temp_prev_images.push(images5Mobile[i].file);
        } else {
          temp_image.push(images5Mobile[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section5_images_mobile = JSON.stringify(temp_prev_images);
      } else {
        data.section5_images_mobile = JSON.stringify([]);
        // delete data.section5_images_mobile;
      }
      if (temp_image.length) {
        data.files_mobile5 = temp_image;
      }
    }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[key][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }

    crateMutate(formData);
  };

  const getImageOptions = async () => {
    try {
      const res = await Api().get(`/content-image?type=${type}&limit=100`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const extractImage = (imageList = []) => {
    let temp_images = [];

    if (imageList?.length) {
      for (let i = 0; i < imageList.length; i++) {
        temp_images.push({ data_url: imageList[i].url, file: imageList[i] });
      }
    }

    return temp_images;
  };

  const getDetails = async () => {
    try {
      const res = await Api().get("/lifeonboard/detail");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      let result = res.data.data;

      result.section1_content_images = result.section1_content_images.map((d) => d._id);

      // mapping
      let temp_images_section_1 = extractImage(result.section1_images);
      let temp_images_section_1_mobile = extractImage(result.section1_images_mobile);
      let temp_images_section_3 = extractImage(result.section3_images);
      let temp_images_section_3_mobile = extractImage(result.section3_images_mobile);
      let temp_images_section_4 = extractImage(result.section4_images);
      let temp_images_section_4_mobile = extractImage(result.section4_images_mobile);
      let temp_images_section_5 = extractImage(result.section5_images);
      let temp_images_section_5_mobile = extractImage(result.section5_images_mobile);

      setId(result._id);
      setImages(temp_images_section_1);
      setImagesMobile(temp_images_section_1_mobile);
      setImages3(temp_images_section_3);
      setImages3Mobile(temp_images_section_3_mobile);
      setImages4(temp_images_section_4);
      setImages4Mobile(temp_images_section_4_mobile);
      setImages5(temp_images_section_5);
      setImages5Mobile(temp_images_section_5_mobile);

      reset({ ...result });
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Life on Board Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    getDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 1 Banner</p>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Desktop): </label>
          <ReactImageUploading style={{ width: "100%" }} value={images} onChange={(imageList) => setImages(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Mobile): </label>
          <ReactImageUploading style={{ width: "100%" }} value={imagesMobile} onChange={(imageList) => setImagesMobile(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="field col-12">
          <label htmlFor="section1_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section1_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section1_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section1_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section1_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section1_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section1_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section1_description && (
              <small id="section1_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section1_content_images">Content : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section1_content_images"
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={name}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.section1_content_images}
                  isLoading={loadingImageOption}
                  options={imagesOption}
                  type={type}
                  onSuccess={refetch}
                  createEndpoint="/content-image"
                  updateEndpoint="/content-image/edit"
                  deleteEnpoint="/content-image"
                  idKey="content_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Section 2</p>
        </div>

        <div className="field col-12">
          <label htmlFor="section2_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Section 3</p>
        </div>
        <div className="field col-12">
          <label htmlFor="section3_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section4_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section4_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section4_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section4_description && (
              <small id="section4_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Desktop): </label>
          <ReactImageUploading multiple style={{ width: "100%" }} value={images3} onChange={(imageList) => setImages3(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Mobile): </label>
          <ReactImageUploading multiple style={{ width: "100%" }} value={images3Mobile} onChange={(imageList) => setImages3Mobile(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="col-12">
          <p className="font-bold uppercase">Section 4</p>
        </div>
        <div className="field col-12">
          <label htmlFor="section4_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section4_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section4_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section4_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section4_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section4_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section4_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section4_description && (
              <small id="section4_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Desktop): </label>
          <ReactImageUploading multiple style={{ width: "100%" }} value={images4} onChange={(imageList) => setImages4(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Mobile): </label>
          <ReactImageUploading multiple style={{ width: "100%" }} value={images4Mobile} onChange={(imageList) => setImages4Mobile(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="col-12">
          <p className="font-bold uppercase">Section 5</p>
        </div>
        <div className="field col-12">
          <label htmlFor="section5_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section5_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section5_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section5_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section5_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section5_description && (
              <small id="section5_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section5_button_name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section5_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section5_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section5_button_route">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section5_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section5_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Desktop): </label>
          <ReactImageUploading style={{ width: "100%" }} value={images5} onChange={(imageList) => setImages5(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images (Mobile): </label>
          <ReactImageUploading style={{ width: "100%" }} value={images5Mobile} onChange={(imageList) => setImages5Mobile(imageList)} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(LifeOnBoardPage, comparisonFn);
