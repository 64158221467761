// hooks
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { MultiSelect } from "primereact/multiselect";
import { InputTextarea } from "primereact/inputtextarea";
// import ReactImageUploading from "react-images-uploading";
import { Image } from "primereact/image";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { useState } from "react";
import removeHTML from "../../utils/removeHTML";
import MultiSelectImagesCRUD from "../../components/MultiSelectImagesCRUD";

const UpdateHomePage = () => {
  let type = 7;
  let name = "Home Page Images";
  const home_id = process.env.REACT_APP_HOME_ID;
  const [imagesSection5, setImagesSection5] = useState([]);
  // const [imagesSection7, setImagesSection7] = useState([]);

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { data: bannerOption, isLoading: bannerLoading } = useQuery("home-banner", () => fetchHandler("home-banner"));
  const { data: destinationOption, isLoading: destinationLoading } = useQuery(["content", 1], () => fetchHandler("/content?type=1&active_status=true"));
  const { data: vesselOption, isLoading: vesselLoading } = useQuery(["content", 2], () => fetchHandler("/content?type=2&active_status=true"));
  const { data: blogOption, isLoading: blogLoading } = useQuery(["content", 5], () => fetchHandler("/content?type=5&active_status=true"));
  const { data: imagesOption, isLoading: loadingImageOption, refetch } = useQuery(["content-image", type], () => getImageOptions());

  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/home/page/edit", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }
        toast.success("Success updated!");
        getHomePageDetails();
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  // functions
  const fetchHandler = async (endpoint) => {
    try {
      const res = await Api().get(endpoint);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      if (res.data.data.length) {
        return res.data.data.map((d) => {
          return { ...d, title: removeHTML(d.title) };
        });
      } else {
        return [];
      }
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const getImageOptions = async () => {
    try {
      const res = await Api().get(`/content-image?type=${type}&limit=100`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  // const onChangeImageSection7 = (imageList) => {
  //   setImagesSection7(imageList);
  // };

  const onSubmit = (data) => {
    const formData = new FormData();
    let file_key = ["files_thumbnail", "files", "files_mobile", "section5_files", "section7_files"];
    // if data is array then its gonna looop
    delete data.section7_images;

    let array_key = ["section5_files", "section7_files"];

    data.home_id = home_id;
    data.banner = JSON.stringify(data.banner || []);
    data.destinations = JSON.stringify(data.destinations || []);
    data.vessels = JSON.stringify(data.vessels || []);
    data.journals = JSON.stringify(data.journals || []);
    data.section5_content_images = JSON.stringify(data.section5_content_images || []);

    // image section 5
    if (imagesSection5.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < imagesSection5.length; i++) {
        // this mean its a prev image
        if (imagesSection5[i].data_url.includes("https")) {
          temp_prev_images.push(imagesSection5[i].file);
        } else {
          temp_image.push(imagesSection5[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section5_images = JSON.stringify(temp_prev_images);
      } else {
        delete data.section5_images;
      }
      if (temp_image.length) {
        data.section5_files = temp_image;
      }
    }

    // image section 7
    // if (imagesSection7.length) {
    //   // prev images
    //   let temp_prev_images = [];
    //   // new images
    //   let temp_image = [];
    //   for (let i = 0; i < imagesSection7.length; i++) {
    //     // this mean its a prev image
    //     if (imagesSection7[i].data_url.includes("https")) {
    //       temp_prev_images.push(imagesSection7[i].file);
    //     } else {
    //       temp_image.push(imagesSection7[i].file);
    //     }
    //   }
    //   if (temp_prev_images.length) {
    //     data.section7_images = JSON.stringify(temp_prev_images);
    //   } else {
    //     delete data.section7_images;
    //   }

    //   if (temp_image.length) {
    //     data.section7_files = temp_image;
    //   }
    // }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);

      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }

      if (isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[key][i]);
        }
      }

      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }

    crateMutate(formData);
  };

  const getHomePageDetails = async () => {
    try {
      const res = await Api().get("/home/detail?home_id=" + home_id);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      // mapping
      let temp_images_section_5 = [];
      // let temp_images_section_7 = [];
      let result = res.data.data;

      if (result.banner) {
        result.banner = result.banner.map((d) => d._id);
      }
      if (result.section5_content_images) {
        result.section5_content_images = result.section5_content_images.map((d) => d._id);
      }

      if (result.destinations) {
        result.destinations = result.destinations.map((d) => d._id);
      }
      if (result.vessels) {
        result.vessels = result.vessels.map((d) => d._id);
      }

      if (result.journals) {
        result.journals = result.journals.map((d) => d._id);
      }

      if (result.section5_images?.length) {
        for (let i = 0; i < result.section5_images.length; i++) {
          temp_images_section_5.push({ data_url: result.section5_images[i].url, file: result.section5_images[i] });
        }
      }

      // if (result.section7_images?.length) {
      //   for (let i = 0; i < result.section7_images.length; i++) {
      //     temp_images_section_7.push({ data_url: result.section7_images[i].url, file: result.section7_images[i] });
      //   }
      // }

      setImagesSection5(temp_images_section_5);
      // setImagesSection7(temp_images_section_7);
      reset({ ...result });
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Homepage Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const bannerTemplateOption = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width="100" src={option?.images[0]?.url} />
        <div className="pl-4">{option.title}</div>
      </div>
    );
  };

  const ImageOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width="50" src={option?.thumbnail_images[0]?.images[0]?.url} />
        <div className="pl-4">{option.title}</div>
      </div>
    );
  };

  useEffect(() => {
    if (!bannerLoading && !blogLoading && !vesselLoading && !destinationLoading) {
      getHomePageDetails();
    }
  }, [bannerLoading, blogLoading, vesselLoading, destinationLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Section 1</p>
        </div>
        {/* <div className="field col-12 lg:col-12">
          <label htmlFor="name">Top Text : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="top_text"
              render={({ field }) => (
                <InputText
                  placeholder="Input top text"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.top_text,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.top_text && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="top_text_url">Top Text Url : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="top_text_url"
              render={({ field }) => (
                <InputText
                  placeholder="Input top text url"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.top_text_url,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.top_text_url && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div> */}
        <div className="field col-12 lg:col-12">
          <label htmlFor="banner">Home Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="banner"
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  itemTemplate={bannerTemplateOption}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!bannerOption?.length}
                  value={field.value}
                  options={bannerOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select banner"
                  display="chip"
                />
              )}
            />
            {formState.errors?.related && (
              <small id="related" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 2</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section2_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section2_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section2_description && (
              <small id="section2_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="destinations">Destinations : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="destinations"
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  itemTemplate={ImageOptionTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!destinationOption?.length}
                  value={field.value}
                  options={destinationOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select item"
                  display="chip"
                />
              )}
            />
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Section 3</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section3_button_name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section3_button_route">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 4</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section4_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section4_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section4_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="vessels">Vessel : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="vessels"
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  itemTemplate={ImageOptionTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!vesselOption?.length}
                  value={field.value}
                  options={vesselOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select item"
                  display="chip"
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 5</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section5_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section5_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section5_content_images">Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section5_content_images"
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={name}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.images}
                  isLoading={loadingImageOption}
                  options={imagesOption}
                  type={type}
                  onSuccess={refetch}
                  createEndpoint="/content-image"
                  updateEndpoint="/content-image/edit"
                  deleteEnpoint="/content-image"
                  idKey="content_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 6</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="name">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section6_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section6_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section6_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="journals">Blog : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="journals"
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  itemTemplate={ImageOptionTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  disabled={!blogOption?.length}
                  value={field.value}
                  options={blogOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder="Select item"
                  display="chip"
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section6_button_name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section6_button_name"
              render={({ field }) => <InputText placeholder="Input button name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section6_button_route">Buttom Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section6_button_route"
              render={({ field }) => <InputText placeholder="Input button route" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 7</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section7_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section7_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section7_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section7_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section7_button_name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section7_button_name"
              render={({ field }) => <InputText placeholder="Input button name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section7_button_route">Buttom Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section7_button_route"
              render={({ field }) => <InputText placeholder="Input button route" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        {/* <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Images: </label>
          <ReactImageUploading multiple style={{ width: "100%" }} value={imagesSection7} onChange={onChangeImageSection7} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section> */}
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(UpdateHomePage, comparisonFn);
