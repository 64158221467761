// hooks
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Image } from "primereact/image";

// api related
import Api from "../api/Api";

// utils
import NewTimeFormatter from "../utils/NewTimeFormatter";

const HomePageImages = ({ permissions }) => {
  let data_limit = 25;
  let name = "Home Page Images";
  let type = 7;
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [uploadThumb, setUploadThum] = useState(null);
  const [uploadThumbMobile, setUploadThumMobile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileMobile, setSelectedFileMobile] = useState(null);

  // api calling
  const {
    isLoading,
    data: result,
    refetch,
  } = useQuery({
    queryKey: ["content-image", type, page],
    queryFn: () => getData(),
  });

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/content-image", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          setShowCreateModal(false);
          throw new Error(response.data.message);
        }
        refetch();
        reset({});
        setSelectedFile(null);
        setShowCreateModal(false);
        toast.success("Image Created!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post("/content-image/edit", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          setShowEditModal(false);
          throw new Error(response.data.message);
        }
        refetch();
        updateForm.reset({});
        setSelectedFile(null);
        setShowEditModal(false);
        toast.success("Image Updated!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/content-image", { data }), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          setSelectItems([]);
          refetch();
          setShowDeleteModal(false);
          setShowDeleteItemModal(false);
          throw new Error(response.data.message);
        }
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Image Deleted!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  // react hook
  const { register, reset, handleSubmit, formState } = useForm();
  const updateForm = useForm();

  // functions
  const getData = async () => {
    try {
      const res = await Api().get(`/content-image?limit=${data_limit}&page=${page}&type=${type}`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const create = (data) => {
    // image required
    if (!selectedFile | !selectedFileMobile) {
      toast.error("Image Required!", { duration: 4000 });
      return;
    }

    const formData = new FormData();

    if (data.description) formData.append("description", data.description);
    if (data.button_route) formData.append("button_route", data.button_route);
    if (data.button_name) formData.append("button_name", data.button_name);

    formData.append("files", selectedFile);
    formData.append("files_mobile", selectedFileMobile);
    formData.append("title", data.title);
    formData.append("type", type);

    crateMutate(formData);
  };

  const update = (data) => {
    const formData = new FormData();

    if (selectedFile) {
      formData.append("files", selectedFile);
      delete data.images;
    } else {
      formData.append("images", JSON.stringify(data.images[0]));
    }

    if (selectedFileMobile) {
      formData.append("files_mobile", selectedFileMobile);
      delete data.images_mobile;
    } else {
      formData.append("images_mobile", JSON.stringify(data.images_mobile[0]));
    }

    if (data.description) formData.append("description", data.description);
    if (data.button_route) formData.append("button_route", data.button_route);
    if (data.button_name) formData.append("button_name", data.button_name);

    formData.append("title", data.title);
    formData.append("content_image_id", data._id);
    formData.append("type", type);
    updateMutate(formData);
  };

  const showNewModal = () => {
    setUploadThum(null);
    setUploadThumMobile(null);
    reset({});
    setSelectedFileMobile(null);
    setShowCreateModal(true);
  };

  const handleUpload = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploadThum(createBlob);
  };

  const handleUploadMobile = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFileMobile(e.target.files[0]);
    setUploadThumMobile(createBlob);
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      content_image_id: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.content_image_id.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      content_image_id: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const editHandler = (data) => {
    const img = data?.images[0];
    const img_mobile = data?.images_mobile[0];
    setUploadThum(img.url);
    setUploadThumMobile(img_mobile.url);
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          {permissions.create && <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={showNewModal} />}
          {permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}
        </div>
      </React.Fragment>
    );
  };

  const imageBodyTemplate = (rowData) => {
    let firstImage = rowData.images[0];
    return (
      <>
        <Image preview src={firstImage.url} alt={rowData.image} className="shadow-2" width="100" />
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button disabled={!permissions.update} icon="pi pi-pencil" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(rowData)} />
        <Button disabled={!permissions.delete} icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage {name} Images</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={result}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Data found."
              header={header}
              responsiveLayout="scroll"
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="a" header="Image" body={imageBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column>
              <Column field="title" header="Title" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="description" header="Description" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="button_route" header="Route" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="created_at" body={(data) => NewTimeFormatter(data.created_at)} header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* create */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header={`Create ${name} Image`} modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={handleSubmit(create)}>
          <div className="field">
            <label htmlFor="name">Title</label>
            <InputText placeholder="Input title" {...register("title", { required: true })} id="name" type="text" className={formState.errors.title && "p-invalid"} />
            {formState.errors.title && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Description</label>
            <InputText placeholder="Input description" {...register("description", { required: false })} id="name" type="text" className={formState.errors.description && "p-invalid"} />
            {formState.errors.description && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Name</label>
            <InputText placeholder="Input button name" {...register("button_name")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Route</label>
            <InputText placeholder="Input route" {...register("button_route")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="field">
            <label htmlFor="description">Upload Image Mobile</label> <br />
            <input onChange={handleUploadMobile} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumbMobile && (
            <div className="">
              <Image preview width="85px" src={uploadThumbMobile} alt="thumbnails" />
            </div>
          )}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* edit */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header={`Edit ${name} Image`} modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form onSubmit={updateForm.handleSubmit(update)}>
          <div className="field">
            <label htmlFor="name">Title</label>
            <InputText placeholder="Input title" {...updateForm.register("title", { required: true })} id="name" type="text" className={updateForm.formState.errors.title && "p-invalid"} />
            {updateForm.formState.errors.title && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Description</label>
            <InputText placeholder="Input description" {...updateForm.register("description", { required: false })} id="name" type="text" className={updateForm.formState.errors.description && "p-invalid"} />
            {updateForm.formState.errors.description && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Name</label>
            <InputText placeholder="Input button name" {...updateForm.register("button_name")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Route</label>
            <InputText placeholder="Input route" {...updateForm.register("button_route")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="field">
            <label htmlFor="description">Upload Image Mobile</label> <br />
            <input onChange={handleUploadMobile} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumbMobile && (
            <div className="">
              <Image preview width="85px" src={uploadThumbMobile} alt="thumbnails" />
            </div>
          )}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Item?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(HomePageImages, comparisonFn);
