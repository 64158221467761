// hooks
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { InputTextarea } from "primereact/inputtextarea";
import { Image } from "primereact/image";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import removeHTML from "../../utils/removeHTML";
import { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import MultiSelectImagesCRUD from "../../components/MultiSelectImagesCRUD";
import JoditEditor from "jodit-react";
import basicJodithConfig from "../../config/basicJodithConfig";

const type_option = [
  { label: "Banner", value: 1 },
  { label: "Content", value: 2 },
  { label: "People", value: 3 },
];

const About = () => {
  const [id, setId] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileMobile, setSelectedFileMobile] = useState(null);
  const [uploadThumb, setUploadThum] = useState(null);
  const [uploadThumbMobile, setUploadThumMobile] = useState(null);

  // hooks
  const createImageForm = useForm();
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { data: imagesOption, isLoading: loadingImages, refetch } = useQuery("about-image", () => fetchHandler("about-image?limit=100"));
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/about/edit", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }
        toast.success("Success updated!");
        getDetails();
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });
  const { isLoading: createImageLoading, mutate: createImageMutate } = useMutation(async (data) => await Api().post("/about-image", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          setShowCreateModal(false);
          throw new Error(response.data.message);
        }
        refetch();
        setSelectedFile(null);
        setSelectedFileMobile(null);
        setUploadThum(null);
        setUploadThumMobile(null);
        createImageForm.reset({});
        setShowCreateModal(false);
        toast.success("Image Created!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  // functions
  const fetchHandler = async (endpoint) => {
    try {
      const res = await Api().get(endpoint);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      if (res.data.data.length) {
        return res.data.data.map((d) => {
          return { ...d, title: removeHTML(d.title) };
        });
      } else {
        return [];
      }
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const onSubmit = (data) => {
    data.about_id = id;
    crateMutate(data);
  };

  const getDetails = async () => {
    try {
      const res = await Api().get("/about/detail");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      // mapping
      let result = res.data.data;

      if (result.banner) {
        result.banner = result.banner.map((d) => d._id);
      }
      if (result.section3_images) {
        result.section3_images = result.section3_images.map((d) => d._id);
      }
      if (result.section4_images) {
        result.section4_images = result.section4_images.map((d) => d._id);
      }
      if (result.section5_images) {
        result.section5_images = result.section5_images.map((d) => d._id);
      }
      if (result.section7_images) {
        result.section7_images = result.section7_images.map((d) => d._id);
      }
      if (result.people_banner) {
        result.people_banner = result.people_banner.map((d) => d._id);
      }

      setId(result._id);
      reset({ ...result });
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  const createImage = (data) => {
    // image required
    if (!selectedFile | !selectedFileMobile) {
      toast.error("Image Required!", { duration: 4000 });
      return;
    }

    const formData = new FormData();
    formData.append("files", selectedFile);
    formData.append("files_mobile", selectedFileMobile);
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("button_route", data.button_route);
    formData.append("button_name", data.button_name);
    formData.append("type", data.type);

    createImageMutate(formData);
  };

  const handleUpload = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploadThum(createBlob);
  };

  const handleUploadMobile = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFileMobile(e.target.files[0]);
    setUploadThumMobile(createBlob);
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            About Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (!loadingImages) {
      getDetails();
    }
  }, [loadingImages]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Section 1</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="banner">Home Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="banner"
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={"About"}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.banner}
                  options={imagesOption?.filter((img) => img.type === 1) || []}
                  type={1}
                  onSuccess={refetch}
                  createEndpoint="/about-image"
                  updateEndpoint="/about-image/edit"
                  deleteEnpoint="/about-image"
                  deletIdKey="ids"
                  idKey="about_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="people_banner">People Banner : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="people_banner"
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={"About"}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.people_banner}
                  options={imagesOption?.filter((img) => img.type === 1) || []}
                  type={1}
                  onSuccess={refetch}
                  createEndpoint="/about-image"
                  updateEndpoint="/about-image/edit"
                  deleteEnpoint="/about-image"
                  deletIdKey="ids"
                  idKey="about_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 1</p>
        </div>
        <div className="field col-12">
          <label htmlFor="section1_description">Content : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section1_description"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 2</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section2_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section2_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section2_description && (
              <small id="section2_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 3</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section3_images">Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section3_images"
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={"About"}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.section3_images}
                  options={imagesOption?.filter((img) => img.type === 2) || []}
                  type={2}
                  onSuccess={refetch}
                  createEndpoint="/about-image"
                  updateEndpoint="/about-image/edit"
                  deleteEnpoint="/about-image"
                  deletIdKey="ids"
                  idKey="about_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section3_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section3_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section3_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section3_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section3_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section3_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section3_description && (
              <small id="section3_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 4</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section4_images">Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section4_images"
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={"About"}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.section4_images}
                  options={imagesOption?.filter((img) => img.type === 2) || []}
                  type={2}
                  onSuccess={refetch}
                  createEndpoint="/about-image"
                  updateEndpoint="/about-image/edit"
                  deleteEnpoint="/about-image"
                  deletIdKey="ids"
                  idKey="about_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section4_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section4_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section4_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section4_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section4_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section4_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section4_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section4_description && (
              <small id="section4_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 5</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section5_images">Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section5_images"
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={"About"}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.section5_images}
                  options={imagesOption?.filter((img) => img.type === 2) || []}
                  type={2}
                  onSuccess={refetch}
                  createEndpoint="/about-image"
                  updateEndpoint="/about-image/edit"
                  deleteEnpoint="/about-image"
                  deletIdKey="ids"
                  idKey="about_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section5_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section5_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section5_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section5_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section5_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section5_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section5_description && (
              <small id="section5_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 6</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section6_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section6_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section6_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section6_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section6_button_name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section6_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section6_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section6_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section6_button_route">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section6_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section6_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section6_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="col-12">
          <p className="font-bold uppercase">Section 7</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section5_images">Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section7_images"
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={"About"}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.section7_images}
                  options={imagesOption?.filter((img) => img.type === 3) || []}
                  type={3}
                  onSuccess={refetch}
                  createEndpoint="/about-image"
                  updateEndpoint="/about-image/edit"
                  deleteEnpoint="/about-image"
                  deletIdKey="ids"
                  idKey="about_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>

        <div className="field col-12 lg:col-12">
          <label htmlFor="section7_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section7_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section7_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section7_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section7_button_name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section7_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section7_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section7_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section7_button_route">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section7_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section7_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section7_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>

      <Dialog visible={showCreateModal} style={{ width: "450px" }} header={`Create About Image`} modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <form onSubmit={createImageForm.handleSubmit(createImage)}>
          <div className="field">
            <label htmlFor="name">Title</label>
            <InputText placeholder="Input title" {...createImageForm.register("title", { required: true })} id="name" type="text" className={createImageForm.formState.errors.title && "p-invalid"} />
            {createImageForm.formState.errors.title && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Description</label>
            <InputText placeholder="Input description" {...createImageForm.register("description", { required: false })} id="name" type="text" className={createImageForm.formState.errors.description && "p-invalid"} />
            {createImageForm.formState.errors.description && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Type</label>
            <Controller
              rules={{ required: true }}
              control={createImageForm.control}
              name="type"
              render={({ field }) => (
                <Dropdown
                  filter
                  showClear
                  disabled
                  ref={field.ref}
                  value={field.value}
                  onBlur={field.onBlur}
                  options={type_option}
                  className={classNames(
                    {
                      "p-invalid": formState.errors.type,
                    },
                    "w-full"
                  )}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  placeholder="Select type"
                />
              )}
            />
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Name</label>
            <InputText placeholder="Input button name" {...createImageForm.register("button_name")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Route</label>
            <InputText placeholder="Input route" {...createImageForm.register("button_route")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="field">
            <label htmlFor="description">Upload Image Mobile</label> <br />
            <input onChange={handleUploadMobile} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumbMobile && (
            <div className="">
              <Image preview width="85px" src={uploadThumbMobile} alt="thumbnails" />
            </div>
          )}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button loading={createImageLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(About, comparisonFn);
