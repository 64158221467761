// hooks
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// components
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import toast from "react-hot-toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

// api related
import Api from "../../api/Api";

// utils
import NewTimeFormatter from "../../utils/NewTimeFormatter";
import { InputTextarea } from "primereact/inputtextarea";
import moment from "moment";

const information_options = [
  {
    label: "Reservation",
    value: 1,
  },
  {
    label: "Partnership",
    value: 2,
  },
  {
    label: "Schedule and Rates",
    value: 3,
  },
  {
    label: "Others",
    value: 4,
  },
];

const Enquire = ({ permissions }) => {
  let data_limit = 25;
  const [page, setPage] = React.useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [pageDatTable, setPageDataTable] = useState({ first: 0, rows: 10, page: 1 });

  // state
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectItems, setSelectItems] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteItemModal, setShowDeleteItemModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  // api calling
  const {
    isLoading,
    data: result,
    refetch,
  } = useQuery({
    queryKey: ["feedback", page],
    queryFn: () => getData(),
  });

  // query
  const { isLoading: deleteLoading, mutate: deleteMutate } = useMutation(async (data) => await Api().delete("/feedback", { data }), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          setSelectItems([]);
          refetch();
          setShowDeleteModal(false);
          setShowDeleteItemModal(false);
          throw new Error(response.data.message);
        }
        setSelectItems([]);
        refetch();
        setShowDeleteModal(false);
        setShowDeleteItemModal(false);
        toast.success("Enquire Deleted!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  // react hook
  const updateForm = useForm();

  // functions
  const getData = async () => {
    try {
      const res = await Api().get(`/feedback?limit=${data_limit}&page=${page}`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      setPageInfo(res.data.pages);
      return res.data.data;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const showDeleteItemConfirmation = (data) => {
    setSelectItems([data]);
    setShowDeleteItemModal(true);
  };

  const deleteSelectedItem = () => {
    let payload = {
      ids: [],
    };

    for (let i = 0; i < selectItems.length; i++) {
      payload.ids.push(selectItems[i]._id);
    }

    deleteMutate(payload);
  };

  const confirmDeleteItem = () => {
    const data = selectItems[0];

    let payload = {
      ids: [data._id],
    };
    deleteMutate(payload);
  };

  const confirmDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const viewHandler = (data) => {
    if (data?.booking_type && data?.booking_type.length) {
      data.booking_type = data.booking_type.join(",");
    } else {
      data.booking_type = "-";
    }

    if (data.information) {
      data.information = information_options.find((d) => d.value === data.information)?.label || "-";
    } else {
      data.information = "-";
    }

    if (data.start_date) {
      data.start_date = moment(data.start_date).format("DD-MM-YYYY");
    } else {
      data.start_date = "-";
    }
    if (data.end_date) {
      data.end_date = moment(data.end_date).format("DD-MM-YYYY");
    } else {
      data.end_date = "-";
    }

    updateForm.reset(data);
    setShowEditModal(true);
  };

  const pageHandler = (data) => {
    setPageDataTable(data);
    setPage(data.page + 1);
  };

  // child components
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">{permissions.delete && <Button disabled={!selectItems.length} label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} />}</div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button disabled={!permissions.update} icon="pi pi-eye" className="p-button-rounded p-button-success mr-2" onClick={() => viewHandler(rowData)} />
        <Button disabled={!permissions.delete} icon="pi pi-trash" className="p-button-rounded p-button-danger mt-2" onClick={() => showDeleteItemConfirmation(rowData)} />
      </div>
    );
  };

  const header = () => {
    return (
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Manage Enquire</h5>
        <span className="block mt-2 md:mt-0 p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    );
  };

  const deleteSingleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteItemModal(false)} />
        <Button label="Yes" loading={deleteLoading} icon="pi pi-check" className="p-button-text" onClick={confirmDeleteItem} />
      </>
    );
  };

  const deleteMultipleItemFooter = () => {
    return (
      <>
        <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setShowDeleteModal(false)} />
        <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedItem} loading={deleteLoading} />
      </>
    );
  };

  return (
    <>
      <div className="grid crud-demo">
        <div className="col-12">
          <div className="card">
            <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
            <DataTable
              loading={isLoading}
              value={result}
              selection={selectItems}
              onSelectionChange={(e) => setSelectItems(e.value)}
              dataKey="_id"
              paginator
              rowsPerPageOptions={[5, 10, 25]}
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              globalFilter={globalFilter}
              emptyMessage="No Data found."
              header={header}
              responsiveLayout="scroll"
              rows={data_limit}
              lazy
              onPage={pageHandler}
              first={pageDatTable.first}
              totalRecords={pageInfo.total_data}
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="first_name" header="First Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="last_name" header="Last Name" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="information" header="Information" body={(data) => information_options.find((d) => d.value === data.information)?.label || "-"} sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="message" header="Message" sortable headerStyle={{ width: "auto", minWidth: "10rem" }}></Column>
              <Column field="created_at" body={(data) => NewTimeFormatter(data.created_at)} header="Created at" sortable headerStyle={{ width: "20%", minWidth: "10rem" }}></Column>
              {permissions.update || permissions.delete ? <Column header="Actions" body={actionBodyTemplate} headerStyle={{ width: "10%", minWidth: "10rem" }}></Column> : null}
            </DataTable>
          </div>
        </div>
      </div>

      {/* view */}
      <Dialog visible={showEditModal} style={{ width: "450px" }} header="View Enquire" modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <form>
          <div className="field">
            <label htmlFor="name">First Name</label>
            <InputText disabled placeholder="" {...updateForm.register("first_name", { required: true })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="name">Last Name</label>
            <InputText disabled placeholder="" {...updateForm.register("last_name", { required: true })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="name">Email</label>
            <InputText disabled placeholder="" {...updateForm.register("email", { required: true })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="name">Information</label>
            <InputText disabled placeholder="" {...updateForm.register("information", { required: true })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="name">Booking</label>
            <InputText disabled placeholder="" {...updateForm.register("booking_type", { required: true })} id="name" type="text" />
          </div>

          <div className="field">
            <label htmlFor="name">Message</label>
            <Controller
              rules={{ required: true }}
              control={updateForm.control}
              name="message"
              defaultValue={""}
              render={({ field }) => <InputTextarea disabled value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className="w-full" placeholder="" autoResize rows="3" cols="20" />}
            />
          </div>
          <div className="field">
            <label htmlFor="name">Start Date</label>
            <InputText disabled placeholder="" {...updateForm.register("start_date", { required: true })} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="name">End Date</label>
            <InputText disabled placeholder="" {...updateForm.register("end_date", { required: true })} id="name" type="text" />
          </div>
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Close" icon="pi pi-times" className="p-button-text" />
            </div>
          </div>
        </form>
      </Dialog>

      {/* delete single */}
      <Dialog visible={showDeleteItemModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteSingleItemFooter} onHide={() => setShowDeleteItemModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && (
            <span>
              Are you sure you want to delete <b>{selectItems[0]?.name}</b>?
            </span>
          )}
        </div>
      </Dialog>

      {/* delete multiple */}
      <Dialog visible={showDeleteModal} style={{ width: "450px" }} header="Confirm" modal footer={deleteMultipleItemFooter} onHide={() => setShowDeleteModal(false)}>
        <div className="flex align-items-center justify-content-center">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectItems.length && <span>Are you sure you want to delete the selected Item?</span>}
        </div>
      </Dialog>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname;
};

export default React.memo(Enquire, comparisonFn);
