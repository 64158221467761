// hooks
import React from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { useMutation, useQuery } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import JoditEditor from "jodit-react";
import { MultiSelect } from "primereact/multiselect";
import { Image } from "primereact/image";
import { InputTextarea } from "primereact/inputtextarea";

// utils
import classNames from "classnames";
import imageSizeOptions from "../../assets/data/image-size.json";
import imagePositionOptions from "../../assets/data/image-position.json";

// api related
import Api from "../../api/Api";
import basicJodithConfig from "../../config/basicJodithConfig";
import { useEffect } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { Dropdown } from "primereact/dropdown";
import MultiSelectImagesCRUD from "../../components/MultiSelectImagesCRUD";

const BlogCreate = () => {
  let type = 5;
  let navigate_route = "/dashboard/blog";
  let name = "Blog";
  const navigate = useNavigate();

  // hooks
  const { control, handleSubmit, watch, formState } = useForm();
  const { fields, append, remove } = useFieldArray({ name: "body", control: control });
  const watchFieldArray = watch("body");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  // query

  const { data: imagesOption, isLoading: loadingImageOption, refetch } = useQuery(["content-image", type], () => getImageOptions());
  const { data: relatedOption, isLoading: loadingRelatedOption } = useQuery(["content", type], () => getRelatedOptions());
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("content", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }
        navigate(navigate_route);
        toast.success(name + " created!");
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  // functions
  const getImageOptions = async () => {
    try {
      const res = await Api().get(`/content-image?type=${type}&limit=100`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };
  const getRelatedOptions = async () => {
    try {
      const res = await Api().get(`/content?type=${type}&active_status=true`);

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      return res.data.data;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };
  const onSubmit = (data) => {
    data.type = type;
    crateMutate(data);
  };

  const successCreateImage = () => {
    refetch();
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Create {name}
          </h4>
        </div>
      </React.Fragment>
    );
  };

  const RelatedOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <Image width="50" src={option?.thumbnail_images[0]?.images[0]?.url} />
        <div className="pl-4">{option.title}</div>
      </div>
    );
  };

  useEffect(() => {
    append({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>

        {/* meta */}
        <div className="col-12">
          <p className="font-bold uppercase">Meta Section</p>
        </div>
        <div className="field col-12">
          <label htmlFor="title">Meta Title : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={""} name="meta_title" render={({ field }) => <InputText placeholder="Input meta title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className="w-full" />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="title">Meta Description : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={""}
              name="meta_description"
              render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className="w-full" placeholder="input meta description" autoResize rows="3" cols="20" />}
            />
          </div>
        </div>

        {/* content */}
        <div className="col-12">
          <p className="font-bold uppercase">Content Section</p>
        </div>
        <div className="field col-12">
          <label htmlFor="thumbnail_images">Thumbnail Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={[]}
              name="thumbnail_images"
              rules={{ required: true }}
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={name}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.thumbnail_images}
                  isLoading={loadingImageOption}
                  options={imagesOption}
                  refetch={refetch}
                  type={type}
                  onSuccess={successCreateImage}
                  createEndpoint="/content-image"
                  updateEndpoint="/content-image/edit"
                  deleteEnpoint="/content-image"
                  idKey="content_image_id"
                  value={field.value}
                />
              )}
            />
            {formState?.errors?.thumbnail_images && (
              <small id="name" className="p-error block pt-1">
                Field Required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="banner">Banner Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="banner"
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={name}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.thumbnail_images}
                  isLoading={loadingImageOption}
                  options={imagesOption}
                  refetch={refetch}
                  type={type}
                  onSuccess={successCreateImage}
                  createEndpoint="/content-image"
                  updateEndpoint="/content-image/edit"
                  deleteEnpoint="/content-image"
                  idKey="content_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="title">Title : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={""} name="title" render={({ field }) => <InputText placeholder="Input title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className="w-full" />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="title">Small Text : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="small_text"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="title">Description : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <JoditEditor
                  ref={field.ref}
                  value={field.value || ""}
                  config={basicJodithConfig}
                  tabIndex={1}
                  onBlur={(e) => {
                    field.onBlur(e);
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="images">Images : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="images"
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelectImagesCRUD
                  name={name}
                  onChange={field.onChange}
                  isInvalid={formState?.errors?.thumbnail_images}
                  isLoading={loadingImageOption}
                  options={imagesOption}
                  refetch={refetch}
                  type={type}
                  onSuccess={successCreateImage}
                  createEndpoint="/content-image"
                  updateEndpoint="/content-image/edit"
                  deleteEnpoint="/content-image"
                  idKey="content_image_id"
                  value={field.value}
                />
              )}
            />
          </div>
        </div>
        <div className="col-12">
          <label htmlFor="images">Content Body : </label>
        </div>
        <section className="card col-12" style={{ borderRadius: 0 }}>
          {controlledFields.map((item, index) => (
            <div className="w-full " key={item.id}>
              <div className="col-12">
                <h4 className="uppercase" style={{ fontWeight: "bold" }}>
                  Content {index + 1}
                </h4>
              </div>
              <div className="p-fluid formgrid grid col-12 ">
                <div className="field col-10">
                  <label htmlFor={`body.${index}.title`}>Content Title : </label>
                  <div className="w-full">
                    <Controller control={control} defaultValue={""} name={`body.${index}.title`} render={({ field }) => <InputText placeholder="Input title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className="w-full" />} />
                  </div>
                </div>
                <div className="field col-2">
                  <label>Action </label>
                  <Button label="Remove field" icon="pi pi-times block " onClick={() => remove(index)} className=" p-button-danger" />
                </div>
                <div className="field col-12">
                  <label htmlFor={`body.${index}.text`}>Content Description : </label>
                  <div className="w-full">
                    <Controller
                      control={control}
                      name={`body.${index}.text`}
                      render={({ field }) => (
                        <JoditEditor
                          ref={field.ref}
                          value={field.value || ""}
                          config={basicJodithConfig}
                          tabIndex={1}
                          onBlur={(e) => {
                            field.onBlur(e);
                          }}
                          onChange={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field col-12 lg:col-12">
                  <label htmlFor={`body.${index}.images`}>Content Images : </label>
                  <div className="w-full">
                    <Controller
                      control={control}
                      defaultValue={[]}
                      name={`body.${index}.images`}
                      render={({ field }) => (
                        <MultiSelectImagesCRUD
                          name={name}
                          onChange={field.onChange}
                          isInvalid={formState?.errors?.thumbnail_images}
                          isLoading={loadingImageOption}
                          options={imagesOption}
                          refetch={refetch}
                          type={type}
                          onSuccess={successCreateImage}
                          createEndpoint="/content-image"
                          updateEndpoint="/content-image/edit"
                          deleteEnpoint="/content-image"
                          idKey="content_image_id"
                          value={field.value}
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="field col-12 ">
                  <label htmlFor={`body.${index}.type`}>Image Position : </label>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name={`body.${index}.type`}
                    render={({ field }) => <Dropdown filter showClear onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={imagePositionOptions} placeholder="Select image position" />}
                  />
                </div>
                <div className="field col-12 ">
                  <label htmlFor={`body.${index}.image_size`}>Image Size : </label>
                  <Controller
                    control={control}
                    defaultValue={null}
                    name={`body.${index}.image_size`}
                    render={({ field }) => <Dropdown filter showClear disabled={item?.type === 5} onBlur={field.onBlur} id="status" ref={field.ref} value={field.value} onChange={(e) => field.onChange(e)} options={imageSizeOptions} placeholder="Select image size" />}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className="col-12 -mt-2 ">
            <Button type="button" onClick={() => append({})} label="Add more content" loading={createLoading} className=" p-button-primary" />
          </div>
        </section>
        <div className="field col-12 ">
          <label htmlFor="bottom_text">Bottom Text : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={""} name="bottom_text" render={({ field }) => <InputText placeholder="Input bottom text" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />} />
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="bottom_button_name">Button Name: </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={""}
              name="bottom_button_name"
              render={({ field }) => <InputText placeholder="Input button name" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        <div className="field col-12 ">
          <label htmlFor="bottom_button_name">Button Route: </label>
          <div className="w-full">
            <Controller
              control={control}
              defaultValue={""}
              name="bottom_button_route"
              render={({ field }) => <InputText placeholder="Input button route" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="related">Related Content: </label>
          <div className="w-full">
            <Controller
              control={control}
              name="related"
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  itemTemplate={RelatedOptionTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  value={field.value}
                  options={relatedOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder={loadingRelatedOption ? "Loading please wait..." : "Select content"}
                  display="chip"
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="related">Related Content: (2) </label>
          <div className="w-full">
            <Controller
              control={control}
              name="related2"
              defaultValue={[]}
              render={({ field }) => (
                <MultiSelect
                  filter
                  showClear
                  itemTemplate={RelatedOptionTemplate}
                  className="w-full"
                  emptyFilterMessage="no data found"
                  value={field.value}
                  options={relatedOption}
                  onChange={(e) => field.onChange(e)}
                  optionLabel="title"
                  optionValue="_id"
                  placeholder={loadingRelatedOption ? "Loading please wait..." : "Select content"}
                  display="chip"
                />
              )}
            />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="active_status">Active Status : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={false} name="active_status" render={({ field }) => <InputSwitch onChange={(e) => field.onChange(e)} checked={field.value} />} />
          </div>
        </div>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to={navigate_route}>
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(BlogCreate, comparisonFn);
