import React from "react";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import Api from "../api/Api";
import { useEffect } from "react";

const Dashboard = () => {
  const { data: dashboardData } = useQuery("dashboard", () => getData("/dashboard/count"), { initialData: {}, enabled: false });

  const getData = async (endpointPath) => {
    try {
      const response = await Api().get(endpointPath);

      if (response.data.status !== 200) {
        toast.error(response.data.message);
        throw new Error("an error occurred");
      }
      return response.data.data;
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  useEffect(() => {
    console.log(dashboardData?.incomplete_order?.new?.amount);
  }, [dashboardData]);

  return (
    <div className="grid">
      {/* <div className="col-12 md:col-6 lg:col-6">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block font-medium mb-3 text-lg uppercase">incomplete order new</span>
              <div className="text-900 flex flex-column" style={{ gap: "10px" }}>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.new?.amount}
                  <span className="pl-2 text-sm uppercase">amount</span>
                </div>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.new.qty}
                  <span className="pl-2 text-sm">QTY</span>
                </div>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="fas fa-solid fa-plus text-blue-500 text-xl" />
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 md:col-6 lg:col-6">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block mb-3 text-lg uppercase">incomplete order pending</span>
              <div className="text-900 flex flex-column" style={{ gap: "10px" }}>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.pending.amount}
                  <span className="pl-2 text-sm uppercase">amount</span>
                </div>
                <div className="text-green-500 ">
                  {dashboardData?.incomplete_order?.pending.qty}
                  <span className="pl-2 text-sm">QTY</span>
                </div>
              </div>
            </div>
            <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "2.5rem", height: "2.5rem" }}>
              <i className="fas fa-solid fa-clock-rotate-left text-orange-500 text-xl" />
            </div>
          </div>
        </div>
      </div> */}

      <div className="col-12 ">
        <div className="card">
          <h5 className="uppercase font-semibold" style={{ fontSize: "14px" }}>
            registered user
          </h5>
          <div class="p-datatable p-component p-datatable-responsive-scroll">
            <div class="p-datatable-wrapper">
              <table class="p-datatable-table" role="table">
                <thead class="p-datatable-thead">
                  <tr role="row">
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 7 Days</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 14 Days</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 1 Month</span>
                      </div>
                    </th>
                    <th class="" role="columnheader">
                      <div class="p-column-header-content">
                        <span class="p-column-title">Last 1 Year</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody class="p-datatable-tbody">
                  <tr role="row" class="">
                    <td>{dashboardData?.registered_user?.last_7}</td>
                    <td>{dashboardData?.registered_user?.last_14}</td>
                    <td>{dashboardData?.registered_user?.last_1m}</td>
                    <td>{dashboardData?.registered_user?.last_1y}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.pathname === nextProps.location?.pathname && prevProps.colorMode === nextProps.colorMode;
};

export default React.memo(Dashboard, comparisonFn);
