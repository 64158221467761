// hooks
import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "react-query";

// conponents
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { InputTextarea } from "primereact/inputtextarea";
import ReactImageUploading from "react-images-uploading";

// utils
import classNames from "classnames";

// api related
import Api from "../../api/Api";
import { useState } from "react";

const VesselPage = () => {
  const [image, setImage] = useState([]);
  const [imageMobile, setImageMobile] = useState([]);
  const [id, setId] = useState("");

  // hooks
  const { control, handleSubmit, formState, reset } = useForm();

  // query
  const { isLoading: createLoading, mutate: crateMutate } = useMutation(async (data) => await Api().post("/vessel/edit", data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }
        toast.success("Success updated!");
        getDetails();
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  // functions
  const onChangeImage = (imageList) => {
    setImage(imageList);
  };

  const onChangeImageMobile = (imageList) => {
    setImageMobile(imageList);
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    let file_key = ["files_thumbnail", "files", "files_mobile"];
    // if data is array then its gonna looop

    let array_key = ["no"];

    data.id = id;

    // image section 5
    if (image.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < image.length; i++) {
        // this mean its a prev image
        if (image[i].data_url.includes("https")) {
          temp_prev_images.push(image[i].file);
        } else {
          temp_image.push(image[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section1_images = JSON.stringify(temp_prev_images);
      } else {
        data.section1_images = [];
      }
      if (temp_image.length) {
        data.files = temp_image;
      }
    }

    // image section 7
    if (imageMobile.length) {
      // prev images
      let temp_prev_images = [];
      // new images
      let temp_image = [];
      for (let i = 0; i < imageMobile.length; i++) {
        // this mean its a prev image
        if (imageMobile[i].data_url.includes("https")) {
          temp_prev_images.push(imageMobile[i].file);
        } else {
          temp_image.push(imageMobile[i].file);
        }
      }
      if (temp_prev_images.length) {
        data.section1_images_mobile = JSON.stringify(temp_prev_images);
      } else {
        data.section1_images_mobile = [];
      }
      if (temp_image.length) {
        data.files_mobile = temp_image;
      }
    }

    for (const key in data) {
      let isFile = file_key.find((data) => data === key);
      let isArrayKey = array_key.find((data) => data === key);
      if (isFile && !isArrayKey) {
        formData.append(key, data[key][0]);
      }

      if (!isFile && isArrayKey) {
        for (let i = 0; i < data[isArrayKey].length; i++) {
          formData.append(key, data[isArrayKey][i]);
        }
      }
      if (!isFile && !isArrayKey) {
        formData.append(key, data[key]);
      }
    }

    crateMutate(formData);
  };

  const getDetails = async () => {
    try {
      const res = await Api().get("/vessel/detail");

      if (res.data.status !== 200) {
        throw new Error(res.data.message);
      }

      // mapping
      let section1_images = [];
      let section1_images_mobile = [];
      let result = res.data.data;

      if (result.section1_images?.length) {
        for (let i = 0; i < result.section1_images.length; i++) {
          section1_images.push({ data_url: result.section1_images[i].url, file: result.section1_images[i] });
        }
      }

      if (result.section1_images_mobile?.length) {
        for (let i = 0; i < result.section1_images_mobile.length; i++) {
          section1_images_mobile.push({ data_url: result.section1_images_mobile[i].url, file: result.section1_images_mobile[i] });
        }
      }

      setId(result._id);
      setImage(section1_images);
      setImageMobile(section1_images_mobile);
      reset({ ...result });
      return result;
    } catch (error) {
      toast.error(error.message);
      return error;
    }
  };

  // components
  const leftToolbar = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <h4 className="uppercase" style={{ margin: 0 }}>
            Vessel Page Setting
          </h4>
        </div>
      </React.Fragment>
    );
  };

  useEffect(() => {
    getDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ borderRadius: "0px" }} className="card grid col-12 mx-auto">
        <div className="col-12">
          <Toolbar className="mb-4 w-full" left={leftToolbar} />
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 1</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section1_title">Title : </label>
          <div className="w-full">
            <Controller control={control} defaultValue={""} name="section1_title" render={({ field }) => <InputText placeholder="Input title" value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} id="name" type="text" className={classNames("w-full")} />} />
          </div>
        </div>
        <div className="field col-12">
          <label htmlFor="section1_description">Description : </label>
          <div className="w-full">
            <Controller
              control={control}
              name="section1_description"
              defaultValue={""}
              render={({ field }) => <InputTextarea value={field.value} onBlur={field.onBlur} ref={field.ref} onChange={(e) => field.onChange(e)} className={classNames("w-full")} placeholder="Input description" autoResize rows={4} />}
            />
          </div>
        </div>
        <div className="col-12">
          <p className="font-bold uppercase">Section 2</p>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_title">Title : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_title"
              render={({ field }) => (
                <InputText
                  placeholder="Input title"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_title,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_title && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>

        <div className="field col-12">
          <label htmlFor="section2_description">Description : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              name="section2_description"
              defaultValue={""}
              render={({ field }) => (
                <InputTextarea
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_description,
                    },
                    "w-full"
                  )}
                  placeholder="Input description"
                  autoResize
                  rows={4}
                />
              )}
            />
            {formState.errors?.section2_description && (
              <small id="section2_description" className="p-error block pt-1">
                filed required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_button_name">Button Name : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_button_name"
              render={({ field }) => (
                <InputText
                  placeholder="Input button name"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_button_name,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_button_name && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <div className="field col-12 lg:col-12">
          <label htmlFor="section2_button_route">Button Route : </label>
          <div className="w-full">
            <Controller
              rules={{ required: true }}
              control={control}
              defaultValue={""}
              name="section2_button_route"
              render={({ field }) => (
                <InputText
                  placeholder="Input button route"
                  value={field.value}
                  onBlur={field.onBlur}
                  ref={field.ref}
                  onChange={(e) => field.onChange(e)}
                  id="name"
                  type="text"
                  className={classNames(
                    {
                      "p-invalid": formState.errors?.section2_button_route,
                    },
                    "w-full"
                  )}
                />
              )}
            />
            {formState.errors?.section2_button_route && (
              <small id="name" className="p-error block pt-1">
                field required
              </small>
            )}
          </div>
        </div>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Image (Desktop): </label>
          <ReactImageUploading style={{ width: "100%" }} value={image} onChange={onChangeImage} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <section style={{ borderRadius: 0 }} className="field col-12 ">
          <label htmlFor="">Upload Image (Mobile): </label>
          <ReactImageUploading style={{ width: "100%" }} value={imageMobile} onChange={onChangeImageMobile} maxNumber={6} dataURLKey="data_url" acceptType={["jpg", "png", "jpeg"]}>
            {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
              <div style={{ minHeight: "140px" }} className="p-toolbar p-component w-full flex flex-column justify-content-center align-items-center">
                <div className="flex justify-content-center w-full">
                  <Button type="button" onClick={onImageUpload} {...dragProps} label="Click or Drop here" />
                </div>

                {imageList.length ? (
                  <div className="grid mt-4 w-full">
                    {imageList.map((image, index) => (
                      <div key={index} className="col-6 lg:col-2 relative">
                        <img src={image.data_url} alt="" className="w-full" style={{ aspectRatio: "1/1", objectFit: "cover" }} />
                        <Button type="button" className="p-button-danger absolute top-0 right-0" onClick={() => onImageRemove(index)} icon="pi pi-trash" />
                        <div className="flex" style={{ gap: "10px" }}>
                          <Button type="button" className="w-full" onClick={() => onImageUpdate(index)} label="Update" icon="pi pi-pencil" />
                        </div>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )}
          </ReactImageUploading>
        </section>
        <div className="flex justify-content-center mt-4 w-full">
          <Button label="Save" loading={createLoading} className=" p-button-primary mr-4" />
          <Link to="/dashboard">
            <Button type="button" label="Back" className=" p-button-secondary" />
          </Link>
        </div>
      </form>
      {/* <DevTool control={control} /> */}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location?.path === nextProps.location?.path;
};

export default React.memo(VesselPage, comparisonFn);
