import React from "react";
import { Button } from "primereact/button";
import { useState } from "react";
import { Image } from "primereact/image";
import { MultiSelect } from "primereact/multiselect";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { Dialog } from "primereact/dialog";
import { useMutation } from "react-query";
import Api from "../api/Api";
import toast from "react-hot-toast";
import { InputText } from "primereact/inputtext";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const TemplateCreateImageFooter = ({ showCreateModal }) => {
  return (
    <div style={{ borderTop: "1px solid black", padding: "10px 40px" }}>
      <Button
        onClick={() => {
          if (showCreateModal) showCreateModal();
        }}
        type="button"
        label="Add New Image"
        icon="pi pi-plus"
        className="p-button-success mr-2"
      />
    </div>
  );
};

const MultiSelectImagesCRUD = ({ isInvalid, options, value, onChange, isLoading = false, onSuccess, name = "", type, createEndpoint, idKey, deletIdKey, updateEndpoint, deleteEnpoint }) => {
  const createImageForm = useForm();
  const updateForm = useForm();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileMobile, setSelectedFileMobile] = useState(null);
  const [uploadThumb, setUploadThum] = useState(null);
  const [uploadThumbMobile, setUploadThumMobile] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const [isOnActionTab, setIsOnActionTab] = useState(false);

  const { isLoading: createImageLoading, mutate: createImageMutate } = useMutation(async (data) => await Api().post(createEndpoint, data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          setShowCreateModal(false);
          throw new Error(response.data.message);
        }
        setSelectedFile(null);
        setSelectedFileMobile(null);
        setUploadThum(null);
        setUploadThumMobile(null);
        createImageForm.reset({});
        setShowCreateModal(false);

        onSuccess();
        toast.success("Image Created!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation(async (data) => await Api().post(updateEndpoint, data), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          setShowEditModal(false);
          throw new Error(response.data.message);
        }
        onSuccess();
        updateForm.reset({});
        setSelectedFile(null);
        setShowEditModal(false);
        toast.success("Image Updated!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  const { mutate: deleteMutate } = useMutation(async (data) => await Api().delete(deleteEnpoint, { data }), {
    onSettled: async (response, error) => {
      try {
        if (response.data.status !== 200) {
          throw new Error(response.data.message);
        }

        onSuccess();
        toast.success("Image Deleted!", { duration: 4000 });
      } catch (error) {
        toast.error(error.message || "Something went wrong , try again later", { duration: 5000 });
      }
    },
  });

  const createImage = (data) => {
    // image required
    if (!selectedFile | !selectedFileMobile) {
      toast.error("Image Required!", { duration: 4000 });
      return;
    }

    const formData = new FormData();

    if (data.description) formData.append("description", data.description);
    if (data.button_route) formData.append("button_route", data.button_route);
    if (data.button_name) formData.append("button_name", data.button_name);

    formData.append("files", selectedFile);
    formData.append("files_mobile", selectedFileMobile);
    formData.append("title", data.title);
    formData.append("type", type);

    createImageMutate(formData);
  };

  const update = (data) => {
    const formData = new FormData();

    if (selectedFile) {
      formData.append("files", selectedFile);
      delete data.images;
    } else {
      formData.append("images", JSON.stringify(data.images[0]));
    }

    if (selectedFileMobile) {
      formData.append("files_mobile", selectedFileMobile);
      delete data.images_mobile;
    } else {
      formData.append("images_mobile", JSON.stringify(data.images_mobile[0]));
    }

    if (data.description) formData.append("description", data.description);
    if (data.button_route) formData.append("button_route", data.button_route);
    if (data.button_name) formData.append("button_name", data.button_name);

    formData.append("title", data.title);
    formData.append(idKey, data._id);
    formData.append("type", type);
    updateMutate(formData);
  };

  const editHandler = (data) => {
    const img = data?.images[0];
    const img_mobile = data?.images_mobile[0];
    setUploadThum(img.url);
    setUploadThumMobile(img_mobile.url);
    updateForm.reset({ ...data });
    setShowEditModal(true);
  };

  const handleUpload = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
    setUploadThum(createBlob);
  };

  const showModalCreate = () => {
    setSelectedFile(null);
    setSelectedFileMobile(null);
    setUploadThum(null);
    setUploadThumMobile(null);

    setShowCreateModal(true);
  };

  const handleUploadMobile = (e) => {
    const createBlob = URL.createObjectURL(e.target.files[0]);
    setSelectedFileMobile(e.target.files[0]);
    setUploadThumMobile(createBlob);
  };

  const confirmDeleteItem = (id) => {
    let payload = {};
    payload[deletIdKey ? deletIdKey : idKey] = [id];
    deleteMutate(payload);
  };

  //   child component
  const ImageOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center  w-full" style={{ justifyContent: "space-between" }}>
        <div className="flex align-items-center">
          <Image width="50" src={option?.images[0]?.url} />
          <div className="pl-4">{option.title}</div>
        </div>

        <div onMouseEnter={() => setIsOnActionTab(true)} onMouseLeave={() => setIsOnActionTab(false)} className="flex align-items-center justify-content-end" style={{ width: "auto", height: "100%", zIndex: 999 }}>
          <Button type="button" label="Edit" className="p-button-rounded p-button-warning mr-2" onClick={() => editHandler(option)} />
          <Button
            type="button"
            className="px-2 p-button-danger"
            label="Remove"
            onClick={() => {
              confirmDialog({
                message: "Are you sure you want to delete " + option.title,
                header: "Delete Confirmation",
                icon: "pi pi-exclamation-triangle",
                accept: () => confirmDeleteItem(option._id),
              });
            }}
          />
        </div>

        <ConfirmDialog />
      </div>
    );
  };

  return (
    <React.Fragment>
      <MultiSelect
        filter
        showClear
        panelClassName="multiselect-images"
        panelFooterTemplate={<TemplateCreateImageFooter showCreateModal={showModalCreate} />}
        itemTemplate={ImageOptionTemplate}
        className={classNames(
          {
            "p-invalid": isInvalid,
          },
          "w-full"
        )}
        emptyFilterMessage="no data found"
        disabled={!options?.length}
        value={value}
        options={options}
        onChange={!isOnActionTab ? onChange : null}
        optionLabel="title"
        optionValue="_id"
        placeholder={isLoading ? "Loading please wait..." : "Select images"}
        display="chip"
      />

      {/* create image */}
      <Dialog visible={showCreateModal} style={{ width: "450px" }} header={`Create ${name} Image`} modal className="p-fluid" footer onHide={() => setShowCreateModal(false)}>
        <div>
          <div className="field">
            <label htmlFor="name">Title</label>
            <InputText placeholder="Input title" {...createImageForm.register("title", { required: true })} id="name" type="text" className={createImageForm.formState.errors.title && "p-invalid"} />
            {createImageForm.formState.errors.title && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Description</label>
            <InputText placeholder="Input description" {...createImageForm.register("description", { required: false })} id="name" type="text" className={createImageForm.formState.errors.description && "p-invalid"} />
            {createImageForm.formState.errors.description && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Name</label>
            <InputText placeholder="Input button name" {...createImageForm.register("button_name")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Route</label>
            <InputText placeholder="Input route" {...createImageForm.register("button_route")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="field">
            <label htmlFor="description">Upload Image Mobile</label> <br />
            <input onChange={handleUploadMobile} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumbMobile && (
            <div className="">
              <Image preview width="85px" src={uploadThumbMobile} alt="thumbnails" />
            </div>
          )}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowCreateModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button onClick={createImageForm.handleSubmit(createImage)} label="Save" loading={createImageLoading} icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog visible={showEditModal} style={{ width: "450px" }} header={`Edit ${name} Image`} modal className="p-fluid" footer onHide={() => setShowEditModal(false)}>
        <div>
          <div className="field">
            <label htmlFor="name">Title</label>
            <InputText placeholder="Input title" {...updateForm.register("title", { required: true })} id="name" type="text" className={updateForm.formState.errors.title && "p-invalid"} />
            {updateForm.formState.errors.title && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="name">Description</label>
            <InputText placeholder="Input description" {...updateForm.register("description", { required: false })} id="name" type="text" className={updateForm.formState.errors.description && "p-invalid"} />
            {updateForm.formState.errors.description && (
              <small id="name-help" className="p-error block">
                This field is required
              </small>
            )}
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Name</label>
            <InputText placeholder="Input button name" {...updateForm.register("button_name")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="button_route">Button Route</label>
            <InputText placeholder="Input route" {...updateForm.register("button_route")} id="name" type="text" />
          </div>
          <div className="field">
            <label htmlFor="description">Upload Image</label> <br />
            <input onChange={handleUpload} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumb && (
            <div className="">
              <Image preview width="85px" src={uploadThumb} alt="thumbnails" />
            </div>
          )}
          <div className="field">
            <label htmlFor="description">Upload Image Mobile</label> <br />
            <input onChange={handleUploadMobile} type="file" accept="image/png, image/jpeg, image/jpg" />
          </div>
          {uploadThumbMobile && (
            <div className="">
              <Image preview width="85px" src={uploadThumbMobile} alt="thumbnails" />
            </div>
          )}
          <div className="flex justify-content-end">
            <div className="flex">
              <Button type="button" onClick={() => setShowEditModal(false)} label="Cancel" icon="pi pi-times" className="p-button-text" />
              <Button onClick={updateForm.handleSubmit(update)} loading={updateLoading} label="Save" icon="pi pi-check" className="p-button-text" />
            </div>
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default MultiSelectImagesCRUD;
