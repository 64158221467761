import Api from "./Api";

export default {
  Get() {
    return Api().get("/page?limit=100");
  },
  GetMenu() {
    return Api().get("/page/menu");
  },
  Update(data) {
    return Api().post("/page/edit", data);
  },
  Add(data) {
    return Api().post(`/page`, data);
  },
  Delete(data) {
    return Api().delete(`/page`, { data: data });
  },
};
